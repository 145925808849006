// src/Transactions.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Transactions() {

    const [trxs, setTrxs] = useState(null);
    const [year, setYear] = useState("");
    useEffect(() => {
        const fetchTrxs = async () => {
            try {
                const response = await axios.get(`/api/trx-list/${year}`);
                setTrxs(response.data);
            } catch (error) {
                console.error("Failed to fetch card", error);
            }
        }

        fetchTrxs();
    }, [year]);


    return trxs && trxs.data && trxs.acc ? (
        <div className="container p-0">
            {trxs && (<>
                <h6 className="bg-black text-white p-1 sticky-top">{trxs.acc} Account Statement {year}</h6>
                <p className="button1 sticky-top"><button onClick={()=>setYear('')}>2025</button> <button  onClick={()=>setYear('2024')}>2024</button></p>
                <div className="overflow-scroll">
                    <table className="table table2" width="100%">
                        <thead>
                        {trxs.data[0] &&
                        <tr>
                            {trxs.data[0].map((trx, i) => (
                                <th key={i}>{trx}</th>
                            ))}
                        </tr>}
                        </thead>
                        <tbody>
                        {trxs.data.slice(1).map((row, i) => (
                            <tr key={i}>
                                {row.map((col, index) => (
                                    <td key={'d'+index}>
                                        {col}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>)}
        </div>
    ) : null;
}
export default Transactions;
